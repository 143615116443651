








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { keys, map, sortBy, zipObject } from 'lodash';
import breakpointService from '@/core/responsive/breakpoints/breakpoints.service';
import loggingService from '@/core/logging.service';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import responsiveImageService from '@/core/responsive/image/responsiveImage.service';

export interface IResponsiveImageWidthOnScreenConfig {
    [key: string]: number;
}
export interface IFocalPoint {
    left: number,
    top: number
}

@Component
export default class ResponsiveImage extends Vue {
    @Prop({ type: String, default: '' })
    imageUrl!: string;

    @Prop({ type: [Object, Number], default: 100 })
    widthOnScreen!: IResponsiveImageWidthOnScreenConfig | number;

    /* Can take a single aspectratio number, or an object with a number pr. breakpoint. If a 'default' is provided is will be used when no breakpoint is active:
        {
             xs: 16/9,
             min-sm,max-md: 4/3
             default: 1/2
        }
     */
    @Prop({ type: [Number, Object], default: 16 / 9 })
    aspectRatio!: number | { [breakpoint: string]: number};

    @Prop({ type: Number, default: 200 })
    offset!: number;

    @Prop({ type: Boolean, default: false })
    lowsrc!: boolean;

    @Prop({ type: String, default: 'transparent' })
    bgColor!: string;

    @Prop({ type: Number, default: 75, validator: (value) => value as number >= 1 && value as number <= 100 })
    quality!: string;

    @Prop({ type: String, default: '' })
    alt!: string;

    @Prop({ type: Object })
    focalPoint!: IFocalPoint;

    @Prop({ type: String, default: 'crop', validator: value => ['crop', 'pad'].includes(value as string) })
    mode!: string;

    @Prop({ type: String, default: 'jpg', validator: value => ['jpg', 'png', 'gif'].includes(value as string) })
    format!: string;

    sizes: Array<number> = responsiveImageService.getSizes();

    lazyInitialized: boolean = false;
    lazyLoaded: boolean = false;
    hasSrcsetSupport: boolean = true;
    loadEventFired = true;

    observer: IntersectionObserver | undefined;

    get src(): string {
        if (!this.hasSrcsetSupport) {
            return this.fallbacksrc;
        }

        let lowSrc: string = '';

        if (this.lowsrc) {
            const lowSrcWidth = 100;
            const lowSrcQuality = 5;
            lowSrc = this.formatImageUrl(lowSrcWidth, this.heightFromWidth(lowSrcWidth), lowSrcQuality);
        } else {
            lowSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        }

        return lowSrc;
    }

    get srcset(): string {
        let srcset: string = '';
        if (this.lazyInitialized && this.hasSrcsetSupport) {
            srcset = this.sizes
                .map(width => {
                    return this.formatImageUrl(width, this.heightFromWidth(width)) + ` ${width}w`;
                })
                .join(', ');
        }
        return srcset;
    }

    get fallbacksrc(): string {
        const width = this.sizes[this.sizes.length - 2]; // second largest
        return this.formatImageUrl(width, this.heightFromWidth(width));
    }

    get sizesFromWidthOnScreen(): string {
        let sizes: string = '';

        if (typeof this.widthOnScreen === 'object') {
            sizes = map(this.sortConfigBySize(this.widthOnScreen), (width, screen) => {
                if (!breakpointService.getBreakpoint(screen)) {
                    const errorMessage = `ResponsiveImage: Breakpoint ${screen} is not defined for imageUrl ${
                        this.absoluteImageUrl
                    }`;
                    loggingService.warn(errorMessage);
                }
                return `(min-width: ${breakpointService.getBreakpoint(screen).min}px) ${width}vw`;
            }).join(',');
            sizes += ', 100vw';
        } else {
            sizes = `${this.widthOnScreen}vw`;
        }

        return sizes;
    }

    get style(): Partial<CSSStyleDeclaration> {
        if (this.aspectRatio === 0) {
            return {
                height: '100%',
                width: '100%'
            };
        }

        const padding = `${(100 / this.activeAspectRatio).toFixed(2)}%`;
        return { paddingTop: padding, background: `#${this.normalizedBgColor}` };
    }

    heightFromWidth(width) {
        return Math.round(width / this.activeAspectRatio);
    }

    get activeAspectRatio(): number {
        if (typeof this.aspectRatio === 'number') {
            return this.aspectRatio;
        }

        // Else - object with aspect-ratio pr. breakpoint-def.

        // Find first active breakpoint-def
        const activeBpDef = Object.keys(this.aspectRatio).find(bpDef => breakpointsState.isBreakpointActive(bpDef));
        // Use active or default
        const result = activeBpDef ? this.aspectRatio[activeBpDef] : this.aspectRatio.default;
        if (!result) {
            throw new Error('Provide a breakpoint-definition for all cases or a default');
        }
        return result;
    }

    formatImageUrl(width: number, height: number, quality?: number): string {
        const firstSeparator = !this.imageUrl || this.imageUrl.indexOf('?') === -1 ? '?' : '&';
        const focal = this.focalPoint ? `&center=${this.focalPoint.top},${this.focalPoint.left}` : '';
        const bgColor = this.normalizedBgColor === 'transparent' ? '' : `&bgcolor=${this.normalizedBgColor}`;
        return `${this.absoluteImageUrl}${firstSeparator}format=${this.format}&width=${width}&height=${height}&quality=${quality || this.quality}&mode=${this.mode}${focal}${bgColor}`;
    }

    sortConfigBySize(config: IResponsiveImageWidthOnScreenConfig) {
        const sortedKeys = sortBy(keys(config), key => {
            return config[key];
        });

        return zipObject(
            sortedKeys,
            map(sortedKeys, function(key) {
                return config[key];
            })
        );
    }

    handleLoad(r): void {
        this.loadEventFired = true;
        this.handleLoadedAndLazyInitialized();

        this.$emit('loaded');
    }

    handleLoadedAndLazyInitialized(): void {
        if (this.lazyInitialized && this.loadEventFired) {
            this.lazyLoaded = true;
        }
    }

    get normalizedBgColor(): string {
        return this.bgColor.replace('#', '');
    }

    observe(): void {
        const options = { rootMargin: `${this.offset}px` };
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.lazyInitialized = true;
                    this.handleLoadedAndLazyInitialized();
                    this.unObserve();
                }
            });
        }, options);
        this.observer.observe(this.$el);
    }

    get absoluteImageUrl(): string {
        return responsiveImageService.getBaseUrl() + this.imageUrl;
    }

    unObserve(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    mounted(): void {
        this.hasSrcsetSupport = this.$refs.image ? 'sizes' in this.$refs.image : true;
        this.observe();
    }

    destroyed() {
        this.unObserve();
    }
}
