import {
    ResourceMenuViewModel,
    SettingsViewModel,
    SiteChromeViewModel,
    SiteFooterViewModel
} from '@/types/contentServerContract';
import {
    CountryOptionsViewModel,
    Feature,
    ProductGuideConfigurationViewModel,
    SiteName
} from '@/types/apiServerContract';
import { ImageNotFound, ImageNotFoundOprema } from '@/project/config/constants';
import { isExternalProductGuide } from '@/core/environmentService';

declare global {
    interface Window {
        servercontext: {
            siteChrome: SiteChromeViewModel;
            culture: string;
            customCulture: string;
            erpBusinessEntityId: string;
            marketId: string;
            businessEntityName: string;
            siteId: number;
            languageCode: string;
            countryCode: string;
            contentHost: string;
            productMediaUrl: string;
            url: string;
            apiHost: string;
            siteAssetsUrl: string;
            googleTagManagerId: string;
            preview: boolean;
            isExternalProductGuide: boolean;
            externalProductGuideLink: string;
            externalProductGuideLinkText: string;
            externalProductGuideSettings?: Partial<ExternalProductGuideConfigurationViewModel>;
            externalProductGuideId?: string;
            dictionary: {[key: string]: string};
            theming: {
                themeString: string;
            };
            featureFlags: { name: Feature, active: boolean }[];
            siteName: SiteName;
            maintenance: boolean;
            punchOutJwtToken: string | undefined;
        },
        externalContext?: {
            campaignSource?: string;
        },
        CookieInformation: {
            getConsentGivenFor(string): boolean,
            loadConsent()
        }
    }
}
export interface ExternalProductGuideConfigurationViewModel extends ProductGuideConfigurationViewModel {
    externalId?: string;
}
export interface SiteInfo {
    erpBusinessEntityId: number | null;
    marketId: number | null;
    siteId: number;
    countryCode: string | null;
    url: string | null;
    languageCode: string | null;
    culture: string | null;
    customCulture: string | null;
    countryOptions?: null | CountryOptionsViewModel;
}

class ServerContextService {
    constructor() {
        if (!window.servercontext) {
            throw new Error('### window.servercontext object should exist ###');
        }
        if (!window.servercontext.siteChrome) {
            throw new Error('### window.servercontext.siteChrome object should exist ###');
        }
    }

    public get footer(): SiteFooterViewModel {
        return window.servercontext.siteChrome.siteFooter;
    }

    public get settings(): SettingsViewModel {
        return window.servercontext.siteChrome.settings;
    }

    public get resourceMenu(): ResourceMenuViewModel | null {
        return window.servercontext.siteChrome.resourceMenu;
    }

    public get siteInfo(): SiteInfo {
        const erpBusinessEntityId = parseInt(window.servercontext.erpBusinessEntityId, 10);
        const marketId = parseInt(window.servercontext.marketId, 10);

        return ({
            culture: window.servercontext.culture,
            customCulture: window.servercontext.customCulture,
            url: window.servercontext.url,
            countryCode: window.servercontext.countryCode,
            languageCode: window.servercontext.languageCode,
            siteId: window.servercontext.siteId,
            erpBusinessEntityId: Number.isNaN(erpBusinessEntityId) ? null : erpBusinessEntityId,
            marketId: Number.isNaN(marketId) ? null : marketId
        });
    }

    public get apiHost(): string {
        return window.servercontext.apiHost;
    }

    public get contentHost(): string {
        return window.servercontext.contentHost;
    }

    public get productMediaUrl(): string {
        return window.servercontext.productMediaUrl;
    }

    public get isPreview(): boolean {
        return window.servercontext.preview;
    }

    public get isExternalProductGuide(): boolean {
        return isExternalProductGuide();
    }

    public get themeString(): string {
        return window.servercontext.theming.themeString;
    }

    public get isOprema(): boolean {
        return window.servercontext.siteName === SiteName.Oprema;
    }

    public get siteName(): SiteName {
        return window.servercontext.siteName;
    }

    public productUrl(product: { itemId: string, url?: string, mpn?: string }) {
        const externalProductLink = window.servercontext.externalProductGuideSettings?.externalProductLink;
        return encodeURI(this.isExternalProductGuide && externalProductLink
            ? externalProductLink.replace(/XXX/gi, (product.mpn || product.itemId))
            : product.url ? product.url : '');
    }

    public get externalProductLinkTarget() {
        return this.isExternalProductGuide ? '_blank' : null;
    }

    public get externalProductLinkText() {
        return window.servercontext.externalProductGuideSettings?.externalProductLinkText;
    }

    public set externalProductGuideSettings(settings: Partial<ExternalProductGuideConfigurationViewModel> | undefined) {
        window.servercontext.externalProductGuideSettings = settings;
    }

    public get externalProductGuideSettings(): Partial<ExternalProductGuideConfigurationViewModel> | undefined {
        return window.servercontext.externalProductGuideSettings;
    }

    public get punchOutJwtToken(): string | undefined {
        return window.servercontext.punchOutJwtToken;
    }

    public get imageNotFound() {
        return this.isOprema ? ImageNotFoundOprema : ImageNotFound;
    }

    public isFeatureEnabled(featureName: Feature): boolean {
        const feature = window.servercontext.featureFlags.find(feature => feature.name === featureName);
        return feature?.active ?? false;
    }

    public isAddressSuggestFeatureEnabled():boolean {
        return this.isFeatureEnabled(Feature.AddressSuggest);
    }

    public get cookieConsent(): {
        // eslint-disable-next-line camelcase
        cookie_cat_necessary: undefined | boolean; // undefined indicate cookie dialog is still shown
        // eslint-disable-next-line camelcase
        cookie_cat_functional: undefined | boolean;
        // eslint-disable-next-line camelcase
        cookie_cat_statistic: undefined | boolean;
        // eslint-disable-next-line camelcase
        cookie_cat_marketing: undefined | boolean;
        // eslint-disable-next-line camelcase
        cookie_cat_unclassified: undefined | boolean;
        } {
        return {
            cookie_cat_necessary: window.CookieInformation?.getConsentGivenFor('cookie_cat_necessary') ?? false,
            cookie_cat_functional: window.CookieInformation?.getConsentGivenFor('cookie_cat_functional') ?? false,
            cookie_cat_statistic: window.CookieInformation?.getConsentGivenFor('cookie_cat_statistic') ?? false,
            cookie_cat_marketing: window.CookieInformation?.getConsentGivenFor('cookie_cat_marketing') ?? false,
            cookie_cat_unclassified: window.CookieInformation?.getConsentGivenFor('cookie_cat_unclassified') ?? false
        };
    }
}

export default new ServerContextService();
