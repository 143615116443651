







































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { BreadcrumbItem, FooterCompanyDetails, FooterSitemapColumnViewModel } from '@/types/contentServerContract';
import authService from '@/core/auth/auth.service';
import siteService from '@/project/site/site.service';
import {
    CountryOptionsViewModel,
    EmployeeViewObject,
    SalesRepViewObject
} from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import appStore from '@/core/app/app.store';
import { SHOW_SITE_PICKER } from '@/project/modals/overlays/SitePickerOverlay.service';
import bus from '@/core/bus';
import serverContextService from '@/core/serverContext.service';
const EmployeeBlockItem = () => import('@/project/app/blocks/information/EmployeeBlockItem.vue');
@Component({
    components: { EmployeeBlockItem }
})
export default class PageFooter extends Vue {
    salesRep: SalesRepViewObject | null = null;

    get companyDetails(): FooterCompanyDetails | null {
        return serverContextService.footer?.companyDetails || null;
    }

    get firstLinkColumns(): FooterSitemapColumnViewModel[] {
        return serverContextService?.footer?.sitemapColumns ?? [];
    }

    activateSitePicker() {
        bus.emit(SHOW_SITE_PICKER);
    }

    get isLoggedIn() {
        return authService.isLoggedIn();
    }

    @Watch('isLoggedIn', {
        immediate: true
    })
    onIsLoggedInChange(isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.getSalesRep();
        } else {
            this.salesRep = null;
        }
    }

    get countryCode(): string | null {
        return siteService.getCountryCode();
    }

    get siteOptions(): CountryOptionsViewModel | null {
        return siteService.getCountryOptions();
    }

    get showLanguageInformation(): boolean {
        if (this.siteOptions?.countries.length && (this.siteOptions.countries.length > 1 || this.siteOptions.countries[0].sites.length > 1)) {
            return true;
        }
        return false;
    }

    @Watch('countryCode')
    onCountryCodeChange() {
        if (this.isLoggedIn) {
            this.getSalesRep();
        } else {
            this.salesRep = null;
        }
    }

    get CountryAndLanguageLabel(): string {
        const lang = siteService.getLanguageCode() || '';
        const countryCode = siteService.getCountryCode() || '';
        return `${this.$dictionary.countryNameByCountryCode(countryCode)} / ${this.$dictionary.languageNameByLanguageCode(lang)}`;
    }

    async getSalesRep(): Promise<void> {
        const response = await Api.user.salesRep();
        const salesRepIsValid = this.checkIfSalesrepContainsData(response.salesRep);
        this.salesRep = salesRepIsValid ? response.salesRep! : null;
    }

    checkIfSalesrepContainsData(rep?: SalesRepViewObject) {
        return !!rep && Object.values(rep).some(x => !!x);
    }

    get salesRepAsEmployeeViewObject(): EmployeeViewObject | null {
        if (!this.salesRep) return null;
        return {
            photoUrl: this.salesRep.picture,
            mobilePhone: this.salesRep.mobilePhoneNo,
            directPhone: this.salesRep.directPhoneNo || '',
            displayName: this.salesRep.name || '',
            title: this.salesRep.title || '',
            email: this.salesRep.email || '',
            initials: '',
            id: ''
        };
    }

    get shouldRender(): boolean {
        return !appStore.isTunnelView;
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get companyDetailsSchemaJson() {
        const socialMediaLinks = this.firstLinkColumns.filter(c => c.socialMediaLinks?.length).map(some => some.socialMediaLinks.map(link => link.url));
        const schema: any = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `${document.location.origin}${window.servercontext.url}`,
            logo: `${window.servercontext.siteAssetsUrl}/favicon.svg`,
            foundingDate: this.isOprema ? '2010' : '1986',
            name: this.companyDetails?.companyName
        };
        if (this.companyDetails?.email) {
            schema.email = this.companyDetails?.email;
        }
        if (this.companyDetails?.phoneNumber) {
            schema.telephone = this.companyDetails?.phoneNumber;
        }
        if (this.companyDetails?.phoneNumber || this.companyDetails?.email) {
            schema.contactPoint = {
                '@type': 'ContactPoint',
                telephone: this.companyDetails?.phoneNumber,
                email: this.companyDetails?.email
            };
        }
        if (this.companyDetails?.city && this.companyDetails.addressInformation && this.companyDetails.postalCode) {
            schema.address = {
                '@type': 'PostalAddress',
                addressLocality: this.companyDetails?.city,
                streetAddress: this.companyDetails?.addressInformation,
                postalCode: this.companyDetails?.postalCode
            };
        }
        if (socialMediaLinks.length) {
            schema.sameAs = socialMediaLinks.flat(1) || [];
        }
        return JSON.stringify(schema);
    }

    get breadcrumbsSchemaJson() {
        const jsonldobj:any = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: []
        };
        // Parent pages
        appStore.breadcrumbPrettified.map((item: BreadcrumbItem, index) => {
            jsonldobj.itemListElement.push({
                '@type': 'ListItem',
                position: (index + 1),
                name: item.name,
                item: item.url ? `${document.location.origin}${window.servercontext.url}${item.url}` : window.location.href
            } as any);
        });
        return JSON.stringify(jsonldobj);
    }
}
