import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { createCancellableGet } from '@/core/http/cancellableGet';
import {
    AddOrUpdateDeliveryAddressRequest,
    DeleteDeliveryAddressRequest,
    DeliveryAddressViewModel,
    DeliveryAddressViewObject,
    DeliveryPriceViewModel
} from '@/types/apiServerContract';

export class DeliveryController {
    private readonly urlFactory = createUrlFactory('Delivery');

    private cancellablePrices = createCancellableGet<DeliveryPriceViewModel[]>();

    public async prices(shipToCountryCode: string, shipToAddressCode?: string, shipToPostalCode?: string): Promise<DeliveryPriceViewModel[]> {
        return this.cancellablePrices(this.urlFactory('Prices'), {
            shipToCountryCode,
            shipToAddressCode,
            shipToPostalCode
        });
    }

    public async addOrUpdateDeliveryAddress(addOrUpdateRequest: AddOrUpdateDeliveryAddressRequest, messagesId: string): Promise<DeliveryAddressViewObject> {
        const requestClean: AddOrUpdateDeliveryAddressRequest & { searchKey?: string } = { ...addOrUpdateRequest };
        if (requestClean?.searchKey) {
            delete requestClean.searchKey;
        }
        return httpService.post(this.urlFactory('AddOrUpdateDeliveryAddress'), requestClean, messagesId);
    }

    public async deleteDeliveryAddress(webId: string, messagesId: string): Promise<DeliveryAddressViewObject> {
        return httpService.delete(this.urlFactory('DeleteDeliveryAddress'), { webId } as DeleteDeliveryAddressRequest, undefined, messagesId);
    }

    public async getDeliveryAddresses(): Promise<DeliveryAddressViewModel> {
        return httpService.get(this.urlFactory('GetDeliveryAddresses'));
    }
}
