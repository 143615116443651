import {
    disableBodyScroll as bslDisableBodyScroll,
    enableBodyScroll as bslEnableBodyScroll,
    clearAllBodyScrollLocks as bslClearAllBodyScrollLocks
} from 'body-scroll-lock';
import bus from '@/core/bus';

export const BODY_SCROLL_LOCKED = 'BodyScrollLocked';

class ScrollService {
    bodyLocks = 0;

    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    public scrollToElement(element: HTMLElement, offset: number = 0, duration: number = 200, scrollElement: HTMLElement | undefined = undefined) {
        duration === 0
            ? this.simpleScroll(element.offsetTop + offset, scrollElement)
            : this.animateScroll(element.offsetTop + offset, duration, scrollElement);
    }

    public disableBodyScroll(targetElement: HTMLElement): void {
        if (!targetElement) {
            throw new Error('disableBodyScroll: targetElement is null');
        }
        if (this.bodyLocks === 0) {
            const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
            bus.emit(BODY_SCROLL_LOCKED, scrollBarGap);
        }

        bslDisableBodyScroll(targetElement, {
            reserveScrollBarGap: true,
            allowTouchMove: el => {
                while (el && el !== document.body) {
                    if (el.getAttribute('id') === 'coiOverlay') {
                        return true;
                    }

                    el = el.parentElement;
                }
            }
        });
        this.bodyLocks++;
    }

    public enableBodyScroll(targetElement: HTMLElement): void {
        if (!targetElement) {
            throw new Error('enableBodyScroll: targetElement is null');
        }
        if (this.bodyLocks === 0) throw new Error('enableBodyScroll: no locks');
        this.bodyLocks--;
        if (this.bodyLocks === 0) {
            bus.emit(BODY_SCROLL_LOCKED, 0);
        }
        bslEnableBodyScroll(targetElement);
    }

    public clearAllBodyScrollLocks(): void {
        this.bodyLocks = 0;
        bus.emit(BODY_SCROLL_LOCKED, 0);
        bslClearAllBodyScrollLocks();
    }

    public simpleScroll(to: number, scrollElement: HTMLElement | undefined = undefined) {
        if (scrollElement) {
            scrollElement.scrollTop = to;
        } else {
            window.scrollTo(0, to);
        }
    }

    public animateScroll(to, duration, scrollElement: HTMLElement | undefined = undefined) {
        const startingY = scrollElement ? scrollElement.scrollTop : window.pageYOffset;
        const diff = to - startingY;
        let start;
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            if (scrollElement) {
                scrollElement.scrollTop = startingY + diff * percent;
            } else {
                window.scrollTo(0, startingY + diff * percent);
            }

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }

    public getScrollbarWidth() {
        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        if (outer.parentNode) {
            outer.parentNode.removeChild(outer);
        }

        return scrollbarWidth;
    }
}
// t = current time
// b = start value
// c = change in value
// d = duration
(Math as any).easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

export default new ScrollService();
