import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import {
    ProductDetailsViewModel,
    ProductBidsViewModel,
    ProductTileViewModel,
    RelatedModelsViewModel,
    CampaignViewObject,
    RelationViewObject,
    ProductIssueReportRequest,
    ProductSeriesViewObject,
    ReferencedProductsViewModel
} from '@/types/apiServerContract';

export class ProductController {
    private readonly urlFactory = createUrlFactory('Product');

    public async details(productWid: string): Promise<ProductDetailsViewModel> {
        return httpService.get<ProductDetailsViewModel>(this.urlFactory('details'), {
            productWid
        });
    }

    public async bids(productWIds: string[], cancelKey?: string): Promise<ProductBidsViewModel> {
        return httpService.get<ProductBidsViewModel>(this.urlFactory('bids'), {
            productWIds
        }, cancelKey);
    }

    public async alternatives(productWIds: string[]): Promise<ProductTileViewModel> {
        return httpService.get<ProductTileViewModel>(this.urlFactory('alternatives'), {
            productWIds
        });
    }

    public async conditions(productWId: string): Promise<ProductTileViewModel> {
        return httpService.get<ProductTileViewModel>(this.urlFactory('conditions'), {
            productWId
        });
    }

    public async series(productWId: string, productSeries: string): Promise<ProductSeriesViewObject> {
        return httpService.get<ProductSeriesViewObject>(this.urlFactory('series'), {
            productWId,
            productSeries
        });
    }

    public async references(productIds: string[]): Promise<ProductTileViewModel> {
        return httpService.get<ProductTileViewModel>(this.urlFactory('references'), {
            productIds
        });
    }

    public async accessories(productWid: string, categoryIds: string[] = [], from: number = 0, size: number = 5): Promise<ReferencedProductsViewModel> {
        return httpService.get<ReferencedProductsViewModel>(this.urlFactory('accessories'), {
            productWid, categoryIds, from, size
        });
    }

    public async exactAlternatives(productWid: string, categoryIds: string[] = [], from: number = 0, size: number = 5): Promise<ReferencedProductsViewModel> {
        return httpService.get<ReferencedProductsViewModel>(this.urlFactory('exactAlternatives'), {
            productWid, categoryIds, from, size
        });
    }

    public async similarAlternatives(productWid: string, categoryIds: string[] = [], from: number = 0, size: number = 5): Promise<ReferencedProductsViewModel> {
        return httpService.get<ReferencedProductsViewModel>(this.urlFactory('similarAlternatives'), {
            productWid, categoryIds, from, size
        });
    }

    public async relatedModels(itemId: string): Promise<RelatedModelsViewModel> {
        return httpService.get<RelatedModelsViewModel>(this.urlFactory('relatedModels'), {
            itemId
        });
    }

    public async relatedModelsSection(itemId: string, brandName: string, brandSeries: string): Promise<RelationViewObject[]> {
        return httpService.get<RelationViewObject[]>(this.urlFactory('relatedModelsSection'), {
            itemId,
            brandName,
            brandSeries
        });
    }

    public async simpleByItemId(itemIds: string[]): Promise<ProductTileViewModel> {
        return httpService.get<ProductTileViewModel>(this.urlFactory('SimpleByItemId'), {
            itemIds
        });
    }

    public async getCampaignWithIcon(campaignCode: string): Promise<CampaignViewObject> {
        return httpService.get<CampaignViewObject>(this.urlFactory('GetCampaignWithIcon'), {
            campaignCode
        });
    }

    public async reportIssue(payload: ProductIssueReportRequest, messageId: string) {
        return httpService.post(this.urlFactory('ReportIssue'), payload, messageId);
    }
}
