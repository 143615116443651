import {
    AccountDetailOrderType,
    AccountLedgerViewModel, BidRequest,
    BidsQuotesRmaViewModel,
    BidsViewModel,
    LedgerDownloadRequest,
    LedgerRequest,
    NotificationsViewModel,
    OrderHeaderRequest,
    OrderHeaderViewModel,
    OrderHistoryDetailViewModel,
    OrderLinesRequest,
    OrderLinesViewModel,
    PermissionsViewModel,
    SendMailRequest,
    SendMailViewModel
} from '@/types/apiServerContract';
import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { AxiosResponse } from 'axios';

export class AccountController {
    private readonly urlFactory = createUrlFactory('Account');

    public async orderHeaders(request: OrderHeaderRequest): Promise<OrderHeaderViewModel> {
        return httpService.post(this.urlFactory('OrderHeaders'), request);
    }

    public async orderDetail(orderId: string): Promise<OrderHistoryDetailViewModel> {
        return httpService.get(this.urlFactory('OrderDetail'), {
            orderId
        });
    }

    public async quoteDetail(orderId: string): Promise<OrderHistoryDetailViewModel> {
        return httpService.get(this.urlFactory('QuoteDetail'), {
            orderId
        });
    }

    public async blanketDetail(orderId: string): Promise<OrderHistoryDetailViewModel> {
        return httpService.get(this.urlFactory('BlanketDetail'), {
            orderId
        });
    }

    public async orderLines(request: OrderLinesRequest): Promise<OrderLinesViewModel> {
        return httpService.post(this.urlFactory('OrderLines'), request);
    }

    public async ledger(request: LedgerRequest): Promise<AccountLedgerViewModel> {
        const payload: LedgerRequest = request;
        if (payload.showOpenOnly) {
            delete payload.startDate;
            delete payload.endDate;
        }
        return httpService.get(this.urlFactory('Ledger'), payload);
    }

    public async permissions(): Promise<PermissionsViewModel> {
        return httpService.get(this.urlFactory('permissions'));
    }

    public async notifications(): Promise<NotificationsViewModel> {
        return httpService.get(this.urlFactory('notifications'));
    }

    public async sendemail(request: SendMailRequest[]): Promise<SendMailViewModel> {
        return httpService.post(this.urlFactory('SendEmail'), request);
    }

    public async bids(request: BidRequest): Promise<BidsViewModel> {
        return httpService.get(this.urlFactory('Bids'), request);
    }

    public async dashboardStatistics(): Promise<BidsQuotesRmaViewModel> {
        return httpService.get(this.urlFactory('DashboardBidsQuotesRma'));
    }

    public async downloadOrderDetails(orderId: string, orderType: AccountDetailOrderType) {
        const result = await httpService.post<AxiosResponse>(this.urlFactory('DownloadOrderDetails'), {
            orderId,
            orderType
        },
        undefined,
        undefined,
        {
            responseType: 'blob'
        },
        true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    public async downloadLedgers(request: LedgerDownloadRequest) {
        const payload: LedgerRequest = request;
        if (payload.showOpenOnly) {
            delete payload.startDate;
            delete payload.endDate;
        }
        const result = await httpService.post<AxiosResponse>(this.urlFactory('DownloadLedgers'), payload,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    public async downloadBids(request: BidRequest) {
        const payload: BidRequest = request;
        const result = await httpService.post<AxiosResponse>(this.urlFactory('DownloadBids'), payload,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }

    public async downloadOrderHistory(request: OrderHeaderRequest) {
        const result = await httpService.post<AxiosResponse>(this.urlFactory('DownloadOrderHistory'), request,
            undefined,
            undefined,
            {
                responseType: 'blob'
            },
            true);

        const fileName = httpService.getContentDispositionFilename(result);
        const file = result.data as Blob;

        return {
            fileName,
            file
        };
    }
}
