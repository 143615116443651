import dictionaryService from '@/core/translation/dictionary.service';
function get(key: string, args: string[] = []): string {
    return dictionaryService.get(key, args);
}
function translate(key: string): string {
    return dictionaryService.translate(key);
}
function countryNameByCountryCode(countryCode: string): string {
    if (!countryCode) return '';
    return get(`Generic.Countries.${(countryCode || '').toUpperCase()}`);
}
function languageNameByLanguageCode(languageCode: string): string {
    if (!languageCode) return '';
    return get(`Generic.Languages.${(languageCode || '').toUpperCase()}`);
}
// Converts markdown like text with [text](url) to html <a href="url">text</a>
function getAsMarkup(key: string, args: string[] = []): string {
    let text = dictionaryService.get(key, args);

    // replace the linebreaks with <br>
    text = text.replace(/\r\n|\r|\n/g, '<br>');

    // check for links [text](url)
    const elements = text.match(/\[.*?\)/g);
    if (elements && elements.length > 0) {
        for (const el of elements) {
            const txtArray = el.match(/\[(.*?)]/); // get only the txt
            const urlArray = el?.match(/\((.*?)\)/); // get only the link
            const txt = txtArray && txtArray[1];
            const url = urlArray && urlArray[1];
            text = text.replace(el, `<a href="${url}">${txt}</a>`);
        }
    }
    return text;
}

export default {
    install(Vue) {
        Vue.prototype.$dictionary = { get, getAsMarkup, countryNameByCountryCode, languageNameByLanguageCode, translate };
    }
};
declare module 'vue/types/vue' {
    interface Vue {
        $dictionary: {
            get: (key: string, args?: string[]) => string,
            translate(key: string): string,
            getAsMarkup(key: string, args?: string[]): string,
            countryNameByCountryCode: (countryCode: string) => string,
            languageNameByLanguageCode: (languageCode: string) => string,
        };
    }
}
