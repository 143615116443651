























import { Vue, Component, Prop } from 'vue-property-decorator';
import { FrontpageViewModel } from '@/types/contentServerContract';
import CallToAction from '@/project/form/CallToAction.vue';
import { isDevMode } from '@/core/environmentService';
import appStore from '@/core/app/app.store';
import serverContextService from '@/core/serverContext.service';
import themeService from '@/project/app/themes/theme.service';

@Component({
    components: { CallToAction }
})
export default class FrontPage extends Vue {
    @Prop() content!: FrontpageViewModel;

    get isDevMode(): boolean {
        return isDevMode();
    }

    get isTakeOverEnabled() {
        return themeService.isTakeOverEnabled;
    }

    mounted() {
        appStore.hideMobileSearch = true;
        appStore.isFrontPage = true;
        appStore.setHideBreadcrumb(true);
    }

    beforeDestroy() {
        appStore.hideMobileSearch = false;
        appStore.isFrontPage = false;
    }

    get developmentPages() {
        if (this.isDevMode) {
            const pages:{ href: string, text: string }[] = [];
            if (serverContextService.settings.rackConfiguratorPage) {
                pages.push({ href: serverContextService.settings.rackConfiguratorPage, text: 'Rack Configurator' });
            }
            if (serverContextService.settings.becomeACustomerPage) {
                pages.push({ href: serverContextService.settings.becomeACustomerPage, text: 'Become a customer' });
            }
            if (serverContextService.settings.brandsPage) {
                pages.push({ href: serverContextService.settings.brandsPage, text: 'Brands' });
            }
            if (serverContextService.settings.genericBrandProductGuidePage) {
                pages.push({ href: serverContextService.settings.genericBrandProductGuidePage, text: 'Brand Product Guide' });
            }
            if (serverContextService.settings.rmaPage) {
                pages.push({ href: serverContextService.settings.rmaPage, text: 'RMA' });
            }
            if (serverContextService.settings.newsPage) {
                pages.push({ href: serverContextService.settings.newsPage, text: 'News' });
            }
            return pages;
        }
        return [];
    }
}
