












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IModal, ModalState } from '@/core/modals/ModalState';
import { Route } from 'vue-router/types';
import scrollService from '@/core/scroll/scroll.service';
import escEventService from '@/core/esc-event.service';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

@Component
export default class ModalOverlay extends Vue {
    @Prop({ type: Object, required: true })
    modal!: IModal;

    get isModalActive() {
        return this.modal.state === 'entering' || this.modal.state === 'visible';
    }

    modalEntering(el: HTMLElement) {
        if (this.modal.disableBodyScroll) {
            scrollService.disableBodyScroll(el);
        }
    }

    modalVisible(el: HTMLElement) {
        this.modal.state = 'visible';
    }

    modalExiting(el: HTMLElement) {
        if (this.modal.disableBodyScroll) {
            this.modal.disableBodyScroll = false;
            scrollService.enableBodyScroll(this.$refs.contentWrapper);
        }
    }

    modalHidden(el: HTMLElement) {
        this.modal.state = 'hidden';
        this.modal.deferred.resolve(this.modal.data);
    }

    vcoConfig = {
        handler: this.handleClickOutside,
        middleware: this.clickOutsideMiddleware,
        events: ['mousedown', 'mouseup']
    }

    clickOutsideMouseDownElm = null;

    clickOutsideMiddleware(event) {
        if (event.type === 'mousedown') {
            this.clickOutsideMouseDownElm = event.target;
            return false;
        }
        const isClickedOutside = event.target === this.clickOutsideMouseDownElm;
        this.clickOutsideMouseDownElm = null;
        return isClickedOutside;
    }

    handleClickOutside() {
        if (this.modal.disableUserClose) {
            return;
        }
        this.hideModal();
    }

    handleClickHideModal() {
        if (!this.modal.showCloseButton) {
            return;
        }
        this.hideModal();
    }

    hideModal() {
        escEventService.unregister(this.hideModal);
        ModalState.hideModal(this.modal.id);
    }

    get animationEnterClass() {
        const animTypes = {
            left: 'slideInLeft',
            right: 'slideInRight',
            top: 'slideInDown',
            center: 'zoomIn',
            modal: 'zoomIn'
        };
        return this.animationClass(animTypes) + ' u-anim-delay-100 u-anim-dur-300 u-anim-ease-out';
    }

    get animationLeaveClass() {
        const animTypes = {
            left: 'slideOutLeft',
            right: 'slideOutRight',
            top: 'slideOutDown',
            modal: 'fadeOut',
            center: 'fadeOut'
        };
        return this.animationClass(animTypes) + ' u-anim-dur-200 u-anim-ease-in';
    }

    animationClass(animTypes: { [side: string]: string }) {
        return `animated ${animTypes[this.modal.placement]}`;
    }

    get overlayClasses(): string | object {
        if (!this.modal.contentAutoScroll) {
            return this.modal.placement;
        }

        return {
            [this.modal.placement]: true
        };
    }

    get activeBreakpoint() {
        return breakpointsState.activeBreakpoint;
    }

    get closeButtonClass(): string {
        return this.modal.closeButtonPosition || 'right-075';
    }

    @Watch('activeBreakpoint')
    onBreakpointChange(activeBp: string, oldBp: string) {
        if (this.modal.closeOnBreakpointChange && activeBp !== oldBp) {
            this.hideModal();
        }
    }

    @Watch('$route')
    onRouteChange(to: Route, from: Route) {
        if (this.modal.closeOnRouteChange && to.path !== from.path) {
            this.hideModal();
        }
    }

    $refs!: Vue['$refs'] & {
        contentWrapper: HTMLElement
    }

    created() {
        escEventService.register(this.hideModal);
    }

    beforeDestroy() {
        if (this.modal.disableBodyScroll) {
            scrollService.enableBodyScroll(this.$refs.contentWrapper);
        }

        escEventService.unregister(this.hideModal);
    }
}
