import Vue from 'vue';
import Deferred from '@/core/async/Deferred';

export type ComponentType = object | (() => object);

export interface ModalOptions {
    disableBodyScroll: boolean;
    contentAutoScroll: boolean;
    placement: 'left' | 'right' | 'top' | 'center';
    singleton: boolean;
    usePanelWrapper: boolean;
    wide: boolean;
    contentMaxHeight: boolean;
    showCloseButton: boolean;
    disableUserClose: boolean;
    closeButtonPosition: null | 'right-30' | 'right-075';
    closeOnRouteChange: boolean;
    closeOnBreakpointChange: boolean;
}

export interface IModal extends ModalOptions {
    id: string;
    data?: any;
    component: ComponentType;
    componentAttrs: any;
    modalListeners?: any;
    deferred: Deferred<any>;
    state: 'entering' | 'visible' | 'exiting' | 'hidden';
}

interface IModalState {
    modals: IModal[];
}

export class ModalState {
    private static state: IModalState = Vue.observable({
        modals: []
    })

    public static get modals(): IModal[] {
        return this.state.modals;
    }

    public static get activeModal(): IModal | null {
        return this.state.modals[this.state.modals.length - 1];
    }

    public static setActiveModal(modal: IModal) {
        this.state.modals.push(modal);
    }

    public static async hideModal(id: string, data?: any) {
        const modal = this.state.modals.find(x => x.id === id);
        if (modal) {
            if (data) {
                modal.data = data;
            }

            if (modal.state === 'visible' || modal.state === 'exiting') {
                modal.state = 'exiting';
                await modal.deferred.promise;
                this.removeModal(modal);
            } else {
                // Modal was not yet visible, just remove it cleanly - no animation needed
                modal.deferred.resolve();
                this.removeModal(modal);
            }
        }

        return Promise.resolve();
    }

    public static async hideModals() {
        for (const modal of this.modals) {
            await this.hideModal(modal.id);
        }
    }

    private static removeModal(modal: IModal) {
        this.state.modals.splice(this.state.modals.indexOf(modal), 1);
    }
}
